<template>
  <div class="page-title-container d-flex flex-wrap justify-content-between align-items-center mb-3" :class="{ container: !fluid, 'container-fluid': fluid }">
    <slot></slot>
    <slot name="actions"></slot>
  </div>
</template>

<script>

export default {
  props: {
    removeMargin: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
