<template>
  <main class="page-my-profile page-show-my-profile">
    <PageTitleContainer>
      <PageTitle removeMargin>Il mio profilo</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-primary" :to="{ name: 'profile.edit' }">Modifica</router-link>
      </div>
    </PageTitleContainer>

    <div class="flex-grow-1 container py-5">
      <div class="table-responsive" v-if="profile">
        <table class="table table-condensed visea-info-table">
          <tbody>
          <tr>
            <td class="title">Nome</td>
            <td>{{ profile.firstName }} {{ profile.lastName }}</td>
          </tr>
          <tr>
            <td class="title">Email</td>
            <td>{{ profile.email }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';
import rolesMixin from '@/mixins/roles.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';

export default {
  mixins: [rolesMixin],
  components: {
    PageTitle,
    PageTitleContainer,
  },
  data () {
    return {
      profile: null,
    };
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const res = await Promise.all([
        $api.getMyProfile(),
      ]);
      return next(vm => {
        vm.profile = res[0]?.data || {};
      });
    } catch (error) {
      return next(from, vm => vm.$log.error(error));
    }
  },
};

</script>
